import React, { FC, memo, useState } from "react";
import { Modal, Button, Input } from "antd";
import { CalendarDeleteIcon } from "../../../../../assets/SvgIcons";
import { ConfirmationModal } from "../../ConfirmationModal";
import { CkModal, CkButton } from "../../../../../CkUI";
import "./styles.css";

const { TextArea } = Input;

interface IProps {
  showModal: boolean;
  setShowModal: (newValue: boolean) => void;
  updateStatus: (status: string, comment?: string) => void;
}
const ConfirmAppointmentModal: FC<IProps> = ({
  showModal,
  setShowModal,
  updateStatus,
}) => {
  const [comments, setComments] = useState<string>("");
  const [confirmApproval, setConfirmApproval] = useState<boolean>(false);

  const onOk = () => {
    if (comments.length === 0) return;
    setConfirmApproval(true);
  };

  const onCancel = () => {
    setComments("");
    setConfirmApproval(false);
    setShowModal(false);
  };

  return (
    <>
      <CkModal
        className="confirm-appointment-modal"
        zIndex={5000}
        open={showModal}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
        title="El cliente confirmó por otro medio"
        primaryAction={{
          label: "Confirmar",
          onClick: onOk,
          disabled: comments.length === 0
        }}
        tertiaryAction={{
          label: "Cancelar",
          onClick: onCancel
        }}
        actionButtonsDirection={"column"}
      >
        <p>
          Por favor indica el medio de confirmación de cliente, así como
          comentarios relevantes adicionales
        </p>
        <div className="input-container">
          <label htmlFor="comments">Comentarios:</label>
          <TextArea
            rows={4}
            name="comments"
            maxLength={512}
            value={comments}
            onChange={(ev) => setComments(ev.target.value)}
          />
        </div>
      </CkModal>
      <ConfirmationModal
        zIndex={5001}
        visible={confirmApproval}
        onOk={() => {
          setConfirmApproval(false);
          updateStatus("APPROVED", comments);
        }}
        onCancel={() => setConfirmApproval(false)}
        title="Advertencia"
        message="¿Desea proceder?"
        icon={<CalendarDeleteIcon />}
      />
    </>
  );
};

export default memo(ConfirmAppointmentModal);
