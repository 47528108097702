import React, { createRef, useRef, useState, useEffect, memo } from "react";
import loadable from "@loadable/component";
import { Button, Modal, message } from "antd";
import { AudioMutedOutlined } from "@ant-design/icons";
import { CKCloseIcon } from "../../../../../../assets/SvgIcons";
import { getBase64 } from "../DiagnosticForm"
import {
  RcFile,
} from "antd/es/upload/interface";
import { ISelectedFile } from "..";
import { CkMessage } from "../../../../../../CkUI";
import "./styles.css";

// import MicRecorder from "mic-recorder-to-mp3";

const MicRecorder = loadable.lib(
  () => import("mic-recorder-to-mp3")
);

const AudioModal = ({
  fileList,
  setFileList,
  audioVisible,
  setAudioVisible,
}: {
  fileList: Array<ISelectedFile>;
  setFileList: Function;
  audioVisible: boolean;
  setAudioVisible: Function;
}) => {
  //User Context
  const micRecorder = createRef();
  const recorderInstance = useRef<any>();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [blobURL, setBlobURL] = useState<string>("");
  const [audioNewFile, setAudioNewFile] = useState<any>({});
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [newAudio, setNewAudio] = useState<boolean>(true);

  const [whichBrowser, setWhichBrowser] = useState<
    "Safari" | "Opera" | "Edge" | "Chrome" | "Firefox" | "unknown"
  >("Safari");

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      setWhichBrowser("Opera");
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      setWhichBrowser("Edge");
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      setWhichBrowser("Chrome");
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      setWhichBrowser("Safari");
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      setWhichBrowser("Firefox");
      // } else if (
      //   navigator.userAgent.indexOf("MSIE") != -1 ||
      //   !!document.DOCUMENT_NODE == true
      // ) {
      //   setWhichBrowser("IE");
    } else {
      setWhichBrowser("unknown");
    }
  };

  const start = () => {
    if (!!micRecorder.current === false && !!recorderInstance.current === false)
      return;
    if (!!recorderInstance.current === false)
      recorderInstance.current = new micRecorder.current.default({
        bitRate: 128,
      });
    if (isBlocked) {
    } else {
      recorderInstance.current
        .start()
        .then(() => {
          setIsRecording(true);
          setBlobURL("");
          setAudioNewFile({});
          if (newAudio) setNewAudio(false);
        })
        .catch((e: any) => console.error(e));
    }
  };

  const stop = () => {
    if (!!micRecorder.current === false && !!recorderInstance.current === false)
      return;
    if (!!recorderInstance.current === false)
      recorderInstance.current = new micRecorder.current.default({
        bitRate: 128,
      });
    if (isRecording)
      recorderInstance.current
        .stop()
        .getMp3()
        //@ts-ignore
        .then(async ([buffer, blob]) => {
          const videoDuration = 15;
          const listSize = fileList.length + 1;
          const file = new File(buffer, `me-at-thevoice-${listSize}.mp3`, {
            type: blob.type,
            lastModified: Date.now(),
          });
          const duration = await getFileDuration(file);
          if (
            //@ts-ignore
            parseInt(duration) >= videoDuration + 1 ||
            file.size > 1024 * 1024 * 5
          ) {
            CkMessage({
              type: "error",
              text: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
            });
          } else {
            setAudioNewFile(file);
            const blobURL = URL.createObjectURL(blob);
            setBlobURL(blobURL);
          }
          setIsRecording(false);
        })
        .catch((e: any) => console.error(e));
  };

  const getFileDuration = async (file: any) =>
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        //@ts-ignore
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const saveAudio = async () => {
    const prev = [...fileList];
    const listSize = prev.length + 1;
    const file = audioNewFile;
    const preview = await getBase64(file as RcFile);
    const obj: ISelectedFile = {
      file: file,
      name: file.name,
      status: "done",
      uid: `u${listSize.toString()}`,
      preview: preview,
      photoUrl: ""
    };
    prev.push(obj);
    setFileList([...prev]);
    setAudioVisible(false);
  };

  return (
    <Modal
      zIndex={5010}
      maskStyle={{ zIndex: 5009 }}
      className="record-audio-modal"
      open={audioVisible}
      footer={null}
      onCancel={async () => {
        await stop();
        setAudioVisible(false);
      }}
      closeIcon={<CKCloseIcon />}
    >
      <MicRecorder ref={micRecorder} />
      <h1 className="step-title">
        {!isRecording ? "Datos de la consulta" : "Grabando..."}
      </h1>
      {blobURL.length > 0 ? (
        <audio
          src={blobURL}
          //@ts-ignore
          controls="controls"
        />
      ) : null}

      {!isRecording && (
        <div className="audio-recorded">
          {blobURL.length > 0 ? (
            <Button
              style={{ marginTop: "10px" }}
              className="--custom next-button --green"
              onClick={() => saveAudio()}
              disabled={isRecording}
            >
              Hecho
            </Button>
          ) : null}
          <Button
            className={`--custom ${newAudio ? "" : "--underlined"}`}
            type={newAudio ? "default" : "link"}
            onClick={() => start()}
            disabled={isRecording}
          >
            {newAudio ? "Abrir micrófono y grabar" : "Grabar nuevamente"}
          </Button>
        </div>
      )}
      {isRecording && (
        <div className="recording-audio">
        <Button
          className="--custom"
          type="link"
          onClick={stop}
          disabled={!isRecording}
          icon={<AudioMutedOutlined style={{ color: "red" }} size={50} />}
          style={{ color: "red" }}
        >
          Terminar
        </Button>
        </div>
      )}
      
    </Modal>
  );
};

export default memo(AudioModal);
