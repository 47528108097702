import React from "react";
import { Modal, Button } from "antd";
import { CKCloseIcon } from "../../../../../../assets/SvgIcons";
import "./styles.css";

interface IProps {
  icon?: JSX.Element;
  title?: string;
  message?: string;
  subMessage?: string;
  onOk: any;
  onCancel: any;
  visible: boolean;
  okText?: string;
  cancelText?: string;
  onCloseIcon?: Function;
  zIndex?: number;
}

const ConfirmDeleteModal: React.FC<IProps> = ({
  icon,
  title,
  message,
  subMessage,
  onOk,
  onCancel,
  visible,
  okText,
  cancelText,
  onCloseIcon,
  zIndex,
}: IProps) => {
  return (
    <Modal
      title={null}
      footer={null}
      open={visible}
      onCancel={onCloseIcon ? onCloseIcon : onCancel}
      maskClosable={false}
      destroyOnClose={true}
      closeIcon={<CKCloseIcon />}
      zIndex={5010}
      maskStyle={{ zIndex: 5009 }}
      className="confirm-delete-modal"
    >
      {icon}
      <h1>
        {title}
      </h1>
      <p className="message">{message}</p>
      <p
        className="submessage"
      >
        {subMessage}
      </p>
      <div className="buttons-container">
        <Button
          onClick={onOk}
          size="large"
          className="--custom --negative ok-btn"
          type="default"
        >
          {okText ? okText : "Sí"}
        </Button>
        <Button
          onClick={onCancel}
          size="large"
          className="--custom --underlined cancel-btn"
          type="link"
        >
          {cancelText ? cancelText : "No"}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
