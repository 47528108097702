import React, {
  FC,
  useState,
  useCallback,
  memo,
  useRef,
  useEffect,
} from "react";
import { Input, Upload } from "antd";
import {
  RcFile,
  UploadProps as TUploadProps,
} from "antd/es/upload/interface";
import moment from "moment";

import { WorkshopService } from "shared/src/data-models/Workshop";
import AudioModal from "../AudioModal";
import LocaleAttachmentsMap from "../LocaleAttachmentsMap";

import {
  CloseCircleFilled,
  CheckCircleFilled,
  PictureOutlined,
  VideoCameraOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import {
  TrashIcon,
  CkCaretDownIcon,
} from "../../../../../../assets/SvgIcons";
import {
  riskOptions,
  ICard,
  TRisk,
} from "..";
import { ISelectedFile } from "..";
import { parseCurrency } from "../QuotationTotals";

import "./style.css";
import { CkButton, CkCheckbox, CkInputNumber, CkMessage } from "../../../../../../CkUI";  

const { TextArea } = Input;

interface IPack {
  label: string;
  packageName: string;
  packageCode: string;
  servicePackageSetId: number;
  type: string;
}

interface ICow {
  // id: string;
  // name: string;
  // lastName: string;
  value: string;
  label: string;
}

interface IServAdded {
  code: string;
}

interface IPackAdded {
  code: string;
  type: string;
}

interface ITrigger {
  trigger: boolean;
  serviceCode: string | undefined;
  packageCode: string | undefined;
  packageType: string | undefined;
}

interface IProps {
  // sasToken: string;
  services: WorkshopService[];
  servicesArray: Array<ICard>;
  setServicesArray: Function;
  servicesAdded: IServAdded[];
  setServicesAdded: Function;
  packagesAdded: IPackAdded[];
  setPackagesAdded: Function;
  // ComprobatePack: Function;
  // ComprobatePackages: Function;
  packagesItems: Array<IPack>;
  setPackagesItems: Function;
  triggerToClear: ITrigger;
  setTriggerToClear: Function;
  showForm: boolean;
  setShowForm: Function;
  coworkersItems: Array<ICow>;
  isReadOnly: boolean;
  deleteCard: Function;
  data: ICard;
  index: number;
  canEditDiagnose?: boolean;
  canEditQuotation?: boolean;
  mode: "DIAG" | "QUOT";
}

const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const DiagnosticCard: FC<IProps> = ({
  // sasToken,
  services,
  servicesArray,
  setServicesArray,
  servicesAdded,
  setServicesAdded,
  packagesAdded,
  setPackagesAdded,
  // ComprobatePack,
  // ComprobatePackages,
  packagesItems,
  setPackagesItems,
  triggerToClear,
  setTriggerToClear,
  showForm,
  setShowForm,
  coworkersItems,
  isReadOnly,
  deleteCard,
  data,
  index,
  canEditDiagnose,
  canEditQuotation,
  mode,
}) => {
  const firstRender = useRef<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const cardRef = useRef<HTMLDivElement>(null);
  const [risk, setRisk] = useState<TRisk>();
  const [diagnosticMessage, setDiagnosticMessage] = useState<string>("");
  const [pricePerUnit, setPricePerUnit] = useState<number>();
  const [discount, setDiscount] = useState<number>(0);
  const [priceDescription, setPriceDescription] = useState<string>("");
  const [
    customDiagnosticFormatAttachment,
    setCustomDiagnosticFormatAttachment,
  ] = useState<ISelectedFile>();
  const [diagnosticAttachments, setDiagnosticAttachements] = useState<
    ISelectedFile[]
  >([]);
  const [isQuotationApproved, setIsQuotationApproved] = useState<boolean>();
  const [audioVisible, setAudioVisible] = useState<boolean>(false);
  const [allowPackageEditPrice, setAllowPackageEditPrice] =
    useState<boolean>(false);

  const getFileDuration = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadAttachmentsProps = {
    //fileList,
    name: "file",
    showUploadList: false,
    accept: "image/*, video/*, audio/*",
    beforeUpload: async (file: any) => {
      const videoDuration = 15;
      if (diagnosticAttachments?.length >= 4) {
        CkMessage({
          type: "error",
          text: "Solo se permite adjuntar dos archivos.",
        });
        return false;
      }

      if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        CkMessage({
          type: "error",
          text: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
        });
        return false;
      }

      if (file.type.startsWith("video/") || file.type.startsWith("audio/")) {
        const duration = await getFileDuration(file);
        if (
          //@ts-ignore
          parseInt(duration) >= videoDuration + 1 ||
          file.size > 1024 * 1024 * 5
        ) {
          CkMessage({
            type: "error",
            text: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
          });
          return false;
        }
      }

      const preview = await getBase64(file as RcFile);
      setDiagnosticAttachements((prevState) => [
        ...prevState,
        {
          uid: `${file.name}-${moment().unix()}`,
          name: file.name,
          status: "done",
          file: file,
          preview: preview,
          photoUrl: "",
        },
      ]);

      return file;
    },
    customRequest: ({ onSuccess, onError, file }: any) => {
      // saveFilesLocally(onSuccess, onError, file);
    },
  };

  const updateArray = (newService: ICard) => {
    setServicesArray((prevState: ICard[]) => {
      return prevState.map((service, currentIndex) => {
        if (currentIndex === index) return newService;
        return service;
      });
    });
  };

  /**
   * Calculate amount to discount in money
   */
  const calculateLinePercentage = useCallback(() => {
    if (discount <= 0 || pricePerUnit === undefined || pricePerUnit <= 0)
      return 0;
    return (discount / 100) * pricePerUnit;
  }, [pricePerUnit, discount]);

  const calculateLineTotal = useCallback(() => {
    if (pricePerUnit === undefined || pricePerUnit <= 0) return 0;
    return pricePerUnit - (discount <= 0 ? 0 : (discount / 100) * pricePerUnit);
  }, [pricePerUnit, discount]);

  /**
   *
   * @returns true if information is valid, false otherwiser. Is any card isn't completed, changes cant be made
   */
  const isCompleted = useCallback((): boolean => {
    // Validate diagnose
    if (["HIGH", "MEDIUM", "LOW"].includes(data.riskValue) === false)
      return false;
    // Validate quotation
    if (pricePerUnit === undefined || !!pricePerUnit === false) return false;
    return true;
  }, [data]);

  useEffect(() => {
    const _isCompleted = isCompleted();
    if (_isCompleted !== data.isCompleted) {
      updateArray({
        ...data,
        isCompleted: _isCompleted,
      });
    }
  }, [data]);

  useEffect(() => {
    if (firstRender.current === true) return;
    const item: ICard = {
      ...data,
      riskValue: risk || "",
      diagnosticMessage: diagnosticMessage,
      attachments: diagnosticAttachments,
      customFormat: customDiagnosticFormatAttachment,
    };
    if (item.quotation) {
      item.quotation.isApproved =
        isQuotationApproved !== undefined ? isQuotationApproved : true;
      item.quotation.lines[0] = {
        ...item.quotation.lines[0],
        pricePerUnit: pricePerUnit || 0,
        discount: discount,
        amountToDiscount: calculateLinePercentage(),
        lineTotal: calculateLineTotal(),
      };
    } else {
      item.quotation = {
        id: 0,
        isApproved: true,
        lines: [
          {
            id: 0,
            description: "",
            label: item.label,
            pricePerUnit: pricePerUnit || 0,
            discount: discount,
            amountToDiscount: calculateLinePercentage(),
            lineTotal: calculateLineTotal(),
            numberOfUnits: 1,
            editableLine: true,
          },
        ],
      };
    }
    updateArray(item);
  }, [
    risk,
    diagnosticMessage,
    diagnosticAttachments,
    pricePerUnit,
    discount,
    priceDescription,
    customDiagnosticFormatAttachment,
    isQuotationApproved,
  ]);

  useEffect(() => {
    if (data) {
      setRisk(data.riskValue);
      setDiagnosticMessage(data.diagnosticMessage);
      setDiagnosticAttachements(data.attachments);
      if (data.quotation) {
        setIsQuotationApproved(data.quotation.isApproved);
        const pricePerUnit = data.quotation.lines[0]?.pricePerUnit || undefined;
        if (pricePerUnit === undefined || pricePerUnit === 0)
          setAllowPackageEditPrice(true);
        setPricePerUnit(pricePerUnit);
        setDiscount(data.quotation.lines[0]?.discount || 0);
        setPriceDescription(data.quotation.lines[0]?.description || "");
      } else {
        setIsQuotationApproved(true);
        setPricePerUnit(undefined);
        setDiscount(0);
        setPriceDescription("");
      }
      if (data.customFormat) {
        setCustomDiagnosticFormatAttachment(data.customFormat);
      }
      firstRender.current = false;
    }
  }, []);

  return (
    <div className="content-section container-diagnostic-card" ref={cardRef}>
      <div className="header">
        <div className="status-display-container">
          {data.isCompleted ? <CheckCircleFilled /> : <CloseCircleFilled />}
        </div>
        <div className="identification">
          <p className="label">
            {index + 1} - {data.type == "MAN" ? "Mantenimiento" : "Reparación"}
          </p>
          <p className="value">{data.label}</p>
        </div>
        <div className="toogler-container">
          <CkButton
            variant="secondary"
            color="mariner"
            icon={<CkCaretDownIcon />}
            className={[...(isExpanded ? ["expanded"] : ["collapsed"])].join(
              " "
            )}
            onClick={() => setIsExpanded((prevState) => !prevState)}
          />
        </div>
      </div>
      <div
        className={[
          "card-content",
          ...(isExpanded === true ? ["expanded"] : [""]),
          ...(isExpanded === false ? ["collapsed"] : [""]),
        ].join(" ")}
      >
        {mode === "DIAG" && (
          <>
            {canEditDiagnose && (
              <p className="helper">
                Completa los datos requeridos abajo o adjunta tu propio formato
                si cuentas con uno.
              </p>
            )}
            <hr />
            <div className="choose-container second-phase choose-risk">
              <p className="title">Nivel de riesgo</p>
              <div className="options">
                {riskOptions.map((option) => (
                  <CkButton
                    key={`choose-risk-${option.value}`}
                    variant="secondary"
                    className={[
                      "--custom",
                      `--${option.value.toLowerCase()}`,
                      ...(risk === option.value ? ["selected"] : []),
                    ].join(" ")}
                    disabled={canEditDiagnose === false}
                    onClick={() => {
                      setRisk(option.value);
                    }}
                    icon={option.icon}
                  >
                    <span>{option.label}</span>
                  </CkButton>
                ))}
              </div>
            </div>
            <div className="choose-container second-phase type-diagnostic">
              <p className="title">Diagnóstico</p>
              <p className="label">Describe la situación del vehículo</p>
              <div className="input-container">
                <TextArea
                  style={{ width: "100%" }}
                  value={diagnosticMessage}
                  className="textarea-size"
                  rows={4}
                  placeholder="Describe el diagnóstico"
                  onChange={(e) => setDiagnosticMessage(e.target.value)}
                  disabled={canEditDiagnose === false}
                />
                <div className="attachment-buttons-container">
                  {canEditDiagnose && (
                    <>
                      <Upload
                        {...uploadAttachmentsProps}
                        accept="image/png, image/jpeg, image/jpg"
                        multiple
                      >
                        <PictureOutlined />
                      </Upload>

                      <Upload
                        {...uploadAttachmentsProps}
                        accept="video/mp4, video/avi, video/flv, video/wmv, video/mov"
                        multiple
                      >
                        <VideoCameraOutlined />
                      </Upload>

                      <AudioOutlined
                        onClick={(e) => {
                          setAudioVisible(true);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <LocaleAttachmentsMap
                fileList={diagnosticAttachments}
                setFileList={setDiagnosticAttachements}
                readOnly={canEditDiagnose === false}
              />
            </div>
            <hr />
          </>
        )}
        {(canEditDiagnose || mode === "QUOT") && (
          <div className="choose-container second-phase quotation">
            <p className="title">Cotización</p>
            <div
              className="quotation-form-container"
              style={{ alignItems: "center" }}
            >
              {(data.type === "REP" ||
                (data.type === "MAN" && allowPackageEditPrice)) && (
                <div className="form-item price-per-unit">
                  <p className="label">Precio unitario</p>
                  <CkInputNumber
                    variant="rounded"
                    inputProps={{
                      disabled: canEditQuotation === false,
                      value: pricePerUnit,
                      controls: false,
                      min: 0,
                      addonBefore: "$",
                      onChange: (e) => e !== null && setPricePerUnit(e),
                    }}
                  />
                </div>
              )}
              {data.type === "MAN" && allowPackageEditPrice === false && (
                <div className="form-display price-per-unit">
                  <p className="label">Precio unitario</p>
                  <p className="price">{parseCurrency(pricePerUnit || 0)}</p>
                </div>
              )}
              <div className="form-item discount">
                <p className="label">Descuento</p>
                <CkInputNumber
                  variant="rounded"
                  inputProps={{
                    disabled: canEditQuotation === false,
                    value: discount,
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%",
                    controls: false,
                    onChange: (e) => e !== null && setDiscount(e),
                  }}
                />
              </div>
              <div className="form-display show-subtotal-discount">
                <p className="subtotal-discount">
                  {parseCurrency(calculateLinePercentage() * -1)}
                </p>
              </div>
            </div>
            <div className="quotation-form-container">
              <div className="form-item description">
                <p className="label">Descripción</p>
                <TextArea
                  style={{ width: "100%" }}
                  value={priceDescription}
                  className="textarea-size"
                  rows={4}
                  onChange={(e) => setPriceDescription(e.target.value)}
                  disabled={canEditQuotation === false}
                />
              </div>
            </div>
            <div className="quotation-form-container">
              <div className="form-display subtotal">
                <p className="label">Subtotal</p>
                <p className="price">{parseCurrency(calculateLineTotal())}</p>
              </div>
            </div>
          </div>
        )}
        {canEditDiagnose && mode === "DIAG" && servicesArray.length > 1 && (
          <>
            <hr />
            <div className="button-container">
              <CkButton
                variant="link"
                color="danger"
                onClick={() => deleteCard(index)}
                icon={<TrashIcon />}
              >
                Eliminar {data.type === "services" ? "servicio" : "paquete"}
              </CkButton>
            </div>
          </>
        )}
        {canEditQuotation && mode === "QUOT" && (
          <>
            <hr />
            <div className="button-container">
              <CkCheckbox
                checkboxProps={{
                  checked: isQuotationApproved,
                  onClick: () =>
                    setIsQuotationApproved((prevState) => !prevState),
                }}
              >
                Autorizado por el cliente
              </CkCheckbox>
            </div>
          </>
        )}
      </div>

      <AudioModal
        fileList={diagnosticAttachments}
        setFileList={setDiagnosticAttachements}
        audioVisible={audioVisible}
        setAudioVisible={setAudioVisible}
      />
    </div>
  );
};

export default memo(DiagnosticCard);
